import { NgZone } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { environment } from '../environments/environment';

export function AngularFirestoreProjectContract(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebaseConfig, 'firebase-project1', false, null, platformId, zone, null);
}

export function AngularFirestoreProjectYygp(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebaseConfigYYGP, 'firebase-project2', false, null, platformId, zone, null);
}

export function AngularFirestoreProjectYygp1(platformId: Object, zone: NgZone) {
    return new AngularFirestore(environment.firebaseConfigYYGP1, 'firebase-project3', false, null, platformId, zone, null);
  }

export function AngularFirestoreProjectYygpidn(platformId: Object, zone: NgZone) {
  return new AngularFirestore(environment.firebaseConfigYYGPIDN, 'firebase-project4', false, null, platformId, zone, null);
}
  