import { UserProfileService } from './service/user-profile.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appPages: any;
  auth: boolean = false;

  public appPages2 = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    }
  ];

  constructor(
      private uProfile: UserProfileService,
    ) {
    this.initializeApp();
  }

  initializeApp() {
    this.uProfile.userDetailBehaviorSubject.subscribe(x => {
      if (x.userRole) {
        this.auth = true;
        this.menuList(x.userRole, x.agencyId);
      }
    })
  }

  signOut(){
    this.uProfile.storage.clear();
    this.uProfile.afAuth.auth.signOut();
    this.uProfile.minSalAndFood.next([]);
    this.uProfile.userDetailBehaviorSubject.next([]);
    this.uProfile.contractTypeNumber.next([]);
  }


  menuList(i: string, agencyId: string) {
    if (i == 'admin') { 
        this.appPages = [
          {
            title: 'Home',
            url: '/back-office',
            subMenu: false
          },
          {
            title: 'No License',
            url: '/no-licnese-agency',
            subMenu: false
          },
          {
            title: 'Employer',
            url: '/ea/er/my',
            subMenu: false
          },
          {
            title: 'Helper',
            url: '/ea/hp/agency',
            subMenu: false
          },
          {
            title: 'Sales',
            url: '/sales',
            subMenu: false
          },
          {
            title: 'Contract',
            url: '/desktop-contract-list',
            subMenu: false
          },
          {
            title: 'Interview',
            url: '/desktop-contract-list',
            subMenu: false
          },
          {
            title: 'Agency List',
            url: '/agencys',
            subMenu: false
          },
          {
            title: 'User List',
            url: '/userList',
            subMenu: false
          },
          {
            title: 'Visa Start',
            url: '/visa-start',
            subMenu: false
          },
          {
            title: 'Phi Agency',
            url: '/agency-origin',
            subMenu: false
          },
          {
            title: 'Edit Agency Info',
            url: '/agency/'+agencyId,
            subMenu: false
          },
          {
            title: 'Sample Form',
            url: '/document-print',
            subMenu: false
          },
          {
            title: 'Agency Charges',
            url: '/sub-agency-charges',
            subMenu: false
          },
          {
            title: '網頁功能',
            url: '/function-yygp',
            subMenu: false
          },
          {
            title: 'Admin Page',
            url: '/test-page',
            subMenu: false
          },
        ];
    } else if (i == 'subAgencyNoLicenseOwner') {
        this.appPages = [
          {
            title: 'Home',
            url: '/no-licnese-agency',
            subMenu: false
          },
          {
            title: 'Contract (Desktop)',
            url: '/desktop-contract-list',
            subMenu: false
          },
          {
            title: 'Helper',
            url: '/ea/hp/agency',
            subMenu: false
          },
          {
            title: 'Visa Start',
            url: '/visa-start',
            subMenu: false
          },
          {
            title: 'Sample Form',
            url: '/document-print',
            subMenu: false
          },
          {
            title: 'Agency Charges',
            url: '/sub-agency-charges',
            subMenu: false
          },
          {
            title: 'Edit Agency Info',
            url: '/agency/'+agencyId,
            subMenu: false
          },
          {
            title: '網頁功能',
            url: '/function-yygp',
            subMenu: false
          },
        ];
    } else if (i == 'backOffice'){
        this.appPages = [
          {
            title: 'Home',
            url: '/back-office',
            subMenu: false
          },
          {
            title: 'Contract',
            url: '/desktop-contract-list',
            subMenu: false
          },
          {
            title: 'Phi Agency',
            url: '/agency-origin',
            subMenu: false
          },
          {
            title: 'Sample Form',
            url: '/document-print',
            subMenu: false
          },
        ];
    } else if (i == 'philippineAgency'){
        this.appPages = [
          {
            title: 'Home',
            url: '/agency-origin',
            subMenu: false
          },

        ];
    } else {
        this.appPages = [
          {
            title: 'Home',
            url: '/input-proofread',
            subMenu: false
          },

        ];
    }
  }

/*
          {
            title: 'Employer',
            url: '',
            subMenu: false
          },
          {
            title: 'Available',
            url: '/desktop-employer-list/available',
            subMenu: true
          },
          {
            title: 'Request',
            url: '/desktop-employer-list/request',
            subMenu: true
          },
          {
            title: 'Firm',
            url: '/desktop-employer-list/firm',
            subMenu: true
          },
          {
            title: 'Pend',
            url: '/desktop-employer-list/pend',
            subMenu: true
          },
          {
            title: 'Auth',
            url: '/desktop-employer-list/auth',
            subMenu: true
          },
          {
            title: 'Cancel',
            url: '/desktop-employer-list/cancel',
            subMenu: true
          },
          {
            title: 'Helper List',
            url: '',
            subMenu: false
          },
          {
            title: 'Available',
            url: '/desktop-helper-list/available',
            subMenu: true
          },
          {
            title: 'Request',
            url: '/desktop-helper-list/request',
            subMenu: true
          },
          {
            title: 'Firm',
            url: '/desktop-helper-list/firm',
            subMenu: true
          },
          {
            title: 'Pend',
            url: '/desktop-helper-list/pend',
            subMenu: true
          },
          {
            title: 'Auth',
            url: '/desktop-helper-list/auth',
            subMenu: true
          },
          {
            title: 'Cancel',
            url: '/desktop-helper-list/cancel',
            subMenu: true
          },
*/

}
