import { UserProfileService } from './../service/user-profile.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  testCol: Observable<any[]>;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private uProfile: UserProfileService,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database  
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
          this.setUpdateToken(token);
//        this.saveToken(token);
//        this.angularFireDB.object('fcmTokens/').update(data)
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  async requestPermission() {
    await this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.setUpdateToken(token);
        console.log('yes');
      },
      (err) => {
        console.error('Error - ', err);
      }
    );
  }

  setUpdateToken(token: string){
    if (this.uProfile.userRole == 'admin') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmOwner/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmSale/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmMerchant/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmBackOffice/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmRunMan/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmPhilippineAgency/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'owner' || this.uProfile.userRole == 'subAgencyNoLicenseOwner') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmOwner/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmSale/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmMerchant/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmBackOffice/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmRunMan/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmPhilippineAgency/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'managerSales' || this.uProfile.userRole == 'employerSales') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmSale/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhUser/'+this.uProfile.agencyId+'/fcmToken/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'managerMerchant' || this.uProfile.userRole == 'merchant') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmMerchant/'+token).set({'Token': true})
     this.uProfile.afs.doc('dhUser/'+this.uProfile.agencyId+'/fcmToken/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'backOffice') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmBackOffice/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'proofread') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmProofread/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'input') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmInput/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'runMan') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmRunMan/'+token).set({'Token': true})
    } else if (this.uProfile.userRole == 'philippineAgency') {
     this.uProfile.afs.doc('dhAgency/'+this.uProfile.agencyId+'/fcmPhilippineAgency/'+token).set({'Token': true})
    } else {
      // code...
    }

  }

  /**
   * hook method when new notification received in foreground
   */
  async receiveMessage() {
    await this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }



  async deleteToken() {
    await this.angularFireMessaging.getToken
      .pipe(mergeMap(token => this.angularFireMessaging.deleteToken(token)))
      .subscribe(
        (token) => { console.log('Deleted!'); },
      );
  }

}