import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { NavController } from '@ionic/angular';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  userId: string;
  agencyId: string;
  agencyName: string;
  agencyEmail: string;
  userRole: string;
  userStatus: number;
  ayDetail: any;
  userDetail: any;
  userName: string;

  userCtrlRef: AngularFirestoreDocument<any>;

  licenseCategory: number;
  visaOut: number;

  mainCtrlRef: AngularFirestoreDocument<any>;
  mainCtrl: Observable<any>;

  minSalary: number;
  minFoodAllowance: number;
  version: string;
  appCurrentVersion: string;

  userDetailBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  contractTypeNumber: BehaviorSubject<any> = new BehaviorSubject([]);
  minSalAndFood: BehaviorSubject<any> = new BehaviorSubject([]);


  constructor(public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public navCtrl: NavController,
    public storage: Storage,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private router: Router,
  ) {
    this.appCurrentVersion = 'V: 2.60';

    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userId = user.uid;
        //        console.log('userId ' + this.userId);
        this.userCtrlRef = this.afs.doc<any>(`dhUser/` + user.uid);
        this.userCtrlRef.valueChanges().subscribe(res => {
          if (res) {
            this.userDetailBehaviorSubject.next(res);
            this.userDetail = res;
            this.userRole = res.userRole;
            //            console.log('userRole '+this.userRole);

            //            console.log(this.userRole);
            this.storage.set('userRole', this.userRole);
            this.RedirectRoute(this.userRole);
            this.userName = res.name;
            this.agencyId = res.agencyId;
            //            console.log(res.agencyId);
            this.agencyName = res.agencyName;
            this.userStatus = res.userStatus;
            this.checkStorageAgencyId(res.agencyId);
          } else {
            this.storage.get('userRole').then((val) => {
              if (!val) {
                this.navCtrl.navigateRoot('notAuthorized');
              }
            });
          }
        });
        this.mainCtrlRef = this.afs.doc<any>(`adminCtrl/IobyJ8HElCHOxBniYfE5`);
        this.mainCtrlRef.valueChanges().subscribe(res => {
          this.minSalAndFood.next(res);
          this.minSalary = res.minSalary;
          this.minFoodAllowance = res.minFoodAllowance;
          this.checkUpdate(res.yygpVersion);
        });
        //        this.messagingService.receiveMessage();
      } else {
        let a = window.location.href;
        a = a.replace('http://localhost:4200/', '')
        a = a.replace('http://localhost:8100/', '')
        a = a.replace('https://yygp.com.hk/', '')
        a = a.replace('https://www.yygp.com.hk/', '')
        a = a.substring(0, 3);
        if (a == 'ah/' || a == 'hrd' || a == 'erd' || a == 'erl' || a == 'hpl' || a == 'ayp' || a == 'ayp' || a == 'hdl' || a == 'edl' || a == 'hpr' || a == 'hpC') {
        } else {
          this.navCtrl.navigateRoot('login');
        }
      }
    });
  }

  RedirectRoute(userRole: string) {
    if (this.router.url == '/' || this.router.url == '/home' || this.router.url == '/login') {
      if (userRole == 'input' || userRole == 'proofread' || userRole == 'otherAgencyInput' || userRole == 'runMan') {
        this.navCtrl.navigateRoot('input-proofread');
      } else if (userRole == 'subAgencyNoLicenseOwner') {
        this.navCtrl.navigateRoot('no-licnese-agency');
      } else if (userRole == 'owner' || userRole == 'backOffice' || userRole == 'admin') {
        this.navCtrl.navigateRoot('back-office');
      } else if (userRole == 'philippineAgency' || userRole == 'indonesiaPT') {
        this.navCtrl.navigateRoot('agency-origin');
      } else if (userRole == 'employerSales') {
        this.navCtrl.navigateRoot('sales');
      } else if (!userRole) {
        this.navCtrl.navigateRoot('notAuthorized');
      } else if (userRole == 'pending') {
        this.navCtrl.navigateRoot('notAuthorized');
      } else {
        this.navCtrl.navigateRoot('notAuthorized');
      }
    }
  }

  checkUpdate(yygpVersion: string) {
    this.storage.get('version').then((val) => {
      if (val) {
        if (val != yygpVersion) {
          this.storage.set('version', yygpVersion).then(res => {
            if (confirm("New version available. Load New Version?")) {
              window.location.reload();
            }
          });
        }
      } else {
        this.storage.set('version', yygpVersion);
      }
    });
  }

  checkStorageAgencyId(agencyId: string) {
    this.storage.get('agencyId').then((val) => {
      var result;
      if (val) {
        if (val != agencyId) {
          result = agencyId;
          this.storage.set('agencyId', agencyId);
        } else {
          result = val;
        }
      } else {
        result = agencyId;
        this.storage.set('agencyId', agencyId);
      }
      this.afs.doc<any>(`dhAgency/` + result).valueChanges().subscribe(res => {
        this.agencyEmail = res.agencyEmail;
        this.ayDetail = res;
        this.contractTypeNumber.next(res);
        this.licenseCategory = res.licenseCategory;
        this.storage.set('licenseCategory', res.licenseCategory);
        this.visaOut = res.visaOut;
      });
    });
  }




}
