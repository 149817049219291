import { UserProfileService } from './service/user-profile.service';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MessagingService } from './shared/messaging.service';
import { IonicStorageModule } from '@ionic/storage';

import { AngularFireDatabaseModule } from '@angular/fire/database';


// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

import { PathLocationStrategy, LocationStrategy } from '@angular/common';


//  for multiple firebase project
import {
  AngularFirestoreProjectContract,
  AngularFirestoreProjectYygp,
  AngularFirestoreProjectYygp1,
  AngularFirestoreProjectYygpidn,
  
} from './firebase.factory';
import { PLATFORM_ID, NgZone, LOCALE_ID } from '@angular/core';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot(),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [
    UserProfileService, 
    MessagingService, 
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    },
    {
      provide: 'firebase-project1',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreProjectContract
    },
    {
      provide: 'firebase-project2',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreProjectYygp
    },
    {
      provide: 'firebase-project3',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreProjectYygp1
    },
    {
      provide: 'firebase-project4',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreProjectYygpidn
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
