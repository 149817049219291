<ion-app>
  <ion-split-pane contentId="first" disabled="true">
    <ion-menu contentId="first" *ngIf="auth">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false" auto-hide="false" *ngFor="let p of appPages">
            <ion-item *ngIf="p.url" [routerDirection]="'root'" [routerLink]="[p.url]" [class.subMenu1]="p.subMenu">
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>
            <ion-item *ngIf="!p.url" [class.subMenu1]="p.subMenu">
              <ion-label>
                {{p.title}}
              </ion-label>
            </ion-item>

          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="signOut()">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                Sign Out
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="first"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
