export const environment = {
	production: true,
    hmr: false,
  	firebaseConfig: {
		apiKey: "AIzaSyCeTUMQUzZ1Cl8Qdm__xqy81XvVFfLTTQU",
	    authDomain: "directhire-9398b.firebaseapp.com",
	    databaseURL: "https://directhire-9398b.firebaseio.com",
	    projectId: "directhire-9398b",
	    storageBucket: "directhire-9398b.appspot.com",
	    messagingSenderId: "318840277527",
		appId: "1:318840277527:web:39da93e976c7e5edbeea52"
	},  
	firebaseConfigYYGP: {
		apiKey: "AIzaSyCJ7dfTUJCeDiHOVMpi_mYSblC9uFLWzvI",
		authDomain: "yygp-1cf9b.firebaseapp.com",
		databaseURL: "https://yygp-1cf9b.firebaseio.com",
		projectId: "yygp-1cf9b",
		storageBucket: "yygp-1cf9b.appspot.com",
		messagingSenderId: "864395911386",
		appId: "1:864395911386:web:f16ded324383e654f745e3",
		measurementId: "G-6V3YFQ0L3G"
	},
	firebaseConfigYYGP1: {
		apiKey: "AIzaSyBP3ZaUYRrq8NUw1V69TmqTN5LcAKN5FWo",
		authDomain: "yygp1-f832b.firebaseapp.com",
		projectId: "yygp1-f832b",
		storageBucket: "yygp1-f832b.appspot.com",
		messagingSenderId: "613700099390",
		appId: "1:613700099390:web:6929aee17b46d03ee8a06f",
		measurementId: "G-QESGYBVQ3Y"
	},
	firebaseConfigYYGPIDN: {
		apiKey: "AIzaSyD0vKxMFbbiIVq6edVrhBrBPE2MEZDdgY0",
		authDomain: "yygpidn.firebaseapp.com",
		projectId: "yygpidn",
		storageBucket: "yygpidn.appspot.com",
		messagingSenderId: "352148539597",
		appId: "1:352148539597:web:969273e954ec50dae4c184",
		measurementId: "G-P744N2WNWD"
	}
};
