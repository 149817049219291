import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  },
  { path: 'hrd/:db/:agency/:id', loadChildren: () => import('./helper-detail/helper-detail.module').then(m => m.HelperDetailPageModule) },
  { path: 'erd/:db/:agency/:id', loadChildren: () => import('./employer-detail/employer-detail.module').then(m => m.EmployerDetailPageModule) },
  { path: 'hpreg/:agency', loadChildren: () => import('./helper-reg-public/helper-register-public.module').then(m => m.HelperRegisterPublicPageModule) },

  { path: 'hpContractHpInfo/:ctRef/:lang/:firstTime', loadChildren: () => import('./helper_contract_info_register_public/helper_contract_info_register_public.module').then(m => m.HelperContractInfoRegisterPublicPageModule) },


  { path: 'hpexpreg/:helperId/:expId/:lang', loadChildren: () => import('./helper-exp-reg-public/helper-exp-reg-public.module').then(m => m.HelperExperienceRegisterPublicPageModule) },



  { path: 'edl/:db/:lang/:agency/:id', loadChildren: () => import('./employer-detail-lang/employer-detail-lang.module').then(m => m.EmployerDetailLangPageModule) },
  { path: 'hdl/:db/:lang/:agency/:id', loadChildren: () => import('./helper-detail-lang/helper-detail-lang.module').then(m => m.HelperDetailLangPageModule) },


  { path: 'hpl/:lang/:agencyCode', loadChildren: () => import('./helper-list/helper-list.module').then(m => m.HelperListPageModule) },
  { path: 'erl/:lang/:agencyCode', loadChildren: () => import('./employer-list/employer-list.module').then(m => m.EmployerListPageModule) },


  //  { path: 'er/:id', loadChildren: () => import('./employer-detail-login/employer-detail-login.module').then(m => m.EmployerDetailLoginPageModule) },
  //  { path: 'hp/:id', loadChildren: () => import('./helper-detail-login/helper-detail-login.module').then(m => m.HelperDetailLoginPageModule) },

  { path: 'desktop-helper-list/:id', loadChildren: () => import('./desktop-helper-list/desktop-helper-list.module').then(m => m.DesktopHelperListPageModule) },
  { path: 'desktop-employer-list/:id', loadChildren: () => import('./desktop-employer-list/desktop-employer-list.module').then(m => m.DesktopEmployerListPageModule) },



  //  auth
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },

  // user
  { path: 'userDetail/:id', loadChildren: () => import('./user-detail/user-detail.module').then(m => m.UserDetailPageModule) },
  { path: 'userList', loadChildren: () => import('./user-list/user-list.module').then(m => m.UserListPageModule) },
  { path: 'userAuth/:id', loadChildren: () => import('./user-auth/user-auth.module').then(m => m.UserAuthPageModule) },

  // agency
  {
    path: 'ayp/:agencyCode',
    loadChildren: () => import('./agency-detail-public/agency-detail-public.module').then(m => m.AgencyDetailPublicPageRoutingModule)
  },
  {
    path: 'agencys',
    loadChildren: () => import('./agencys/agencys.module').then(m => m.AgencysPageModule)
  },
  {
    path: 'agency/:id',
    loadChildren: () => import('./agency/agency.module').then(m => m.AgencyPageModule)
  },

  { path: 'consulate-name-only', loadChildren: () => import('./consulate-name-only/consulate-name-only.module').then(m => m.ConsulateNameOnlyPageModule) },
  { path: 'contract-photo-doc', loadChildren: () => import('./contract-photo-doc/contract-photo-doc.module').then(m => m.ContractPhotoDocPageModule) },
  { path: 'contractFacilities', loadChildren: () => import('./contract-facilities/contract-facilities.module').then(m => m.ContractFacilitiesPageModule) },
  { path: 'contractPhiCopyForm', loadChildren: () => import('./contract-phi-copy-form/contract-phi-copy-form.module').then(m => m.ContractPhiCopyFormPageModule) },
  { path: 'contractIdnCopyForm', loadChildren: () => import('./contract-idn-copy-form/contract-idn-copy-form.module').then(m => m.ContractIdnCopyFormPageModule) },
  { path: 'consulate-input-with-doc/:id', loadChildren: () => import('./consulate-input-with-doc/consulate-input-with-doc.module').then(m => m.ConsulateInputWithDocPageModule) },
  { path: 'contract-staff-input/:id', loadChildren: () => import('./contract-staff-input/contract-staff-input.module').then(m => m.ContractStaffInputPageModule) },
  { path: 'contract-helper-info-with-doc/:id', loadChildren: () => import('./contract-helper-info-with-doc/contract-helper-info-with-doc.module').then(m => m.ContractHelperInfoWithDocPageModule) },
  { path: 'contract-employer-info-with-doc/:id', loadChildren: () => import('./contract-employer-info-with-doc/contract-employer-info-with-doc.module').then(m => m.ContractEmployerInfoWithDocPageModule) },
  { path: 'contractCategoryNationality', loadChildren: () => import('./contract-category-nationality/contract-category-nationality.module').then(m => m.ContractCategoryNationalityPageModule) },
  { path: 'contractAgency', loadChildren: () => import('./contract-agency/contract-agency.module').then(m => m.ContractAgencyPageModule) },
  { path: 'go-to-visa-start', loadChildren: () => import('./go-to-visa-start/go-to-visa-start.module').then(m => m.GoToVisaStartPageModule) },
  { path: 'report-to-agency', loadChildren: () => import('./report-to-agency/report-to-agency.module').then(m => m.ReportToAgencyPageModule) },

  { path: 'contract-list-break', loadChildren: () => import('./contract-list-break/contract-list-break.module').then(m => m.ContractListPageModule) },


  { path: 'contractDetail/:id', loadChildren: () => import('./contract-detail/contract-detail.module').then(m => m.ContractDetailPageModule) },
  { path: 'contractList/:id', loadChildren: () => import('./contract-list/contract-list.module').then(m => m.ContractListPageModule) },
  { path: 'contractCreate', loadChildren: () => import('./contract-create/contract-create.module').then(m => m.ContractCreatePageModule) },
  { path: 'notAuthorized', loadChildren: () => import('./not-authorized/not-authorized.module').then(m => m.NotAuthorizedPageModule) },
  { path: 'specifc-contract-list/:id', loadChildren: () => import('./specifc-contract-list/specifc-contract-list.module').then(m => m.SpecifcContractListPageModule) },
  { path: 'document-print', loadChildren: () => import('./document-print/document-print.module').then(m => m.DocumentPrintPageModule) },
  { path: 'consulate-submit-register/:id', loadChildren: () => import('./consulate-submit-register/consulate-submit-register.module').then(m => m.ConsulateSubmitRegisterPageModule) },
  { path: 'consulate-receipt-contract-list/:id/:consulate', loadChildren: () => import('./consulate-receipt-contract-list/consulate-receipt-contract-list.module').then(m => m.ConsulateReceiptContractListPageModule) },
  { path: 'pick-up-courier/:id', loadChildren: () => import('./pick-up-courier/pick-up-courier.module').then(m => m.PickUpCourierPageModule) },
  { path: 'contract-from-alley', loadChildren: () => import('./contract-from-alley/contract-from-alley.module').then(m => m.ContractFromAlleyPageModule) },
  { path: 'desktop-contract-list', loadChildren: () => import('./desktop-contract-list/desktop-contract-list.module').then(m => m.DesktopContractListPageModule) },

  // side menu
  { path: 'no-licnese-agency', loadChildren: () => import('./no-licnese-agency/no-licnese-agency.module').then(m => m.NoLicneseAgencyPageModule) },
  { path: 'input-proofread', loadChildren: () => import('./input-proofread/input-proofread.module').then(m => m.InputProofreadPageModule) },
  { path: 'visa-start', loadChildren: () => import('./visa-start/visa-start.module').then(m => m.VisaStartPageModule) },
  { path: 'test-page', loadChildren: () => import('./test-page/test-page.module').then(m => m.TestPagePageModule) },
  { path: 'noti-page', loadChildren: () => import('./noti-page/noti-page.module').then(m => m.NotiPagePageModule) },
  { path: 'push-noti', loadChildren: () => import('./push-noti/push-noti.module').then(m => m.PushNotiPageModule) },
  { path: 'back-office', loadChildren: () => import('./back-office/back-office.module').then(m => m.BackOfficePageModule) },


  { path: 'employer-edit/:id/:type', loadChildren: () => import('./employer-edit/employer-edit.module').then(m => m.EmployerEditPageModule) },
  { path: 'helper-edit/:id/:type', loadChildren: () => import('./helper-edit/helper-edit.module').then(m => m.HelperEditPageModule) },
  { path: 'helper-contact/:id', loadChildren: () => import('./helper-contact/helper-contact.module').then(m => m.HelperContactPageModule) },
  { path: 'exp-edit/:userRef/:expRef', loadChildren: () => import('./exp-edit/exp-edit.module').then(m => m.ExpEditPageModule) },
  { path: 'agency-origin', loadChildren: () => import('./agency-origin/agency-origin.module').then(m => m.AgencyOriginPageModule) },
  { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesPageModule) },
  { path: 'sub-agency-charges', loadChildren: () => import('./sub-agency-charges/sub-agency-charges.module').then(m => m.SubAgencyChargesPageModule) },


  { path: 'ea', loadChildren: () => import('./ea/ea.module').then(m => m.EaPageModule) },
  {
    path: 'requests',
    loadChildren: () => import('./requests/requests.module').then(m => m.RequestsPageModule)
  },
  {
    path: 'request/:id',
    loadChildren: () => import('./request/request.module').then(m => m.RequestPageModule)
  },
  {
    path: 'role-ban-cancel/:role/:cate',
    loadChildren: () => import('./role-ban-cancel/role-ban-cancel.module').then(m => m.RoleBanCancelPageModule)
  },
  {
    path: 'ah/:agencyCode',
    loadChildren: () => import('./ah/ah.module').then(m => m.AhPageModule)
  },
  {
    path: 'function-yygp',
    loadChildren: () => import('./function-yygp/function-yygp.module').then(m => m.FunctionYygpPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'multi-purpose-contract/:id',
    loadChildren: () => import('./multi-purpose-contract/multi-purpose-contract.module').then(m => m.MultiPurposeContractPageModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }



